import {createRouter, createWebHistory} from 'vue-router'
import $ from 'jquery'
import {Message} from "@arco-design/web-vue";

const routes = [
    {
        path:"/",
        redirect:`/account`,
    },
    {
        path:"/login",
        name: 'login',
        component:() => import(`@/views/LoginView`)
    },
    {
        path:"/account",
        name: 'account',
        component:() => import(`@/views/MainPageView`),
        redirect:`/account/manage`,
        children: [
            {
                path:'/account/manage',
                name: 'account-index',
                redirect: `/account/manage/index?year=${new Date().getFullYear()}&month=${new Date().getMonth() + 1}`
            },
            {
                path:'/account/manage/index',
                name: 'account-manage-index',
                component:() => import(`@/views/IndexView`),
            },
            {
                path:'/account/manage/search',
                name: 'account-manage-search',
                component:() => import(`@/views/AccountSearchView`),
            },
            {
                path:'/account/manage/type',
                name: 'account-manage-type',
                component:() => import(`@/views/ManageTypeView`),
            },
            {
                path:'/account/manage/tag',
                name: 'account-manage-tag',
                component:() => import(`@/views/ManageTagView`),
            },
            {
                path: '/account/analysis',
                redirect:`/account/analysis/month?year=${new Date().getFullYear()}&month=${new Date().getMonth() + 1}`,
            },
            {
                path: '/account/analysis/month',
                name: 'account-analysis-month',
                component:() => import(`@/views/AnalysisMonthView`)
            },
            {
                path: '/account/property',
                redirect: '/account/property/manage'
            },
            {
                path: '/account/property/manage',
                name: 'account-property-manage',
                component:() => import(`@/views/PropertyManageView`)
            },
            {
                path: '/account/paid_service',
                redirect: '/account/paid_service/manage'
            },
            {
                path: '/account/paid_service/manage',
                name: 'account-paid-service/manage',
                component:() => import(`@/views/ManagePaidServiceView`)
            },
            {
                path: '/account/paid_service/mail/template',
                name: 'account-paid-service-mail-template',
                component:() => import(`@/views/PaidServiceMailTemplateView`)
            },
        ]
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

//无需登录的path
const notLoginApi = new Set(['/login']);

// 全局前置守卫
router.beforeEach((to, from, next) => {
    if(notLoginApi.has(to.path)) {
        next();
    }else{
        $.ajax({
            url:"http://39.107.113.111:8081/api/user/log/status",
            headers:{
                token:window.localStorage.getItem("token")
            },
            success(resp){
                if(resp.code === 20000 && resp.data){
                    next();
                }else{
                    Message.info("请先登录")
                    router.push('/login')
                }
            },
            error(){
                Message.info("登录状态获取失败")
                router.push('/login')
            }
        })
    }


});

export default router
